:root {
    --font-calendar: calc(var(--unit) * 0.75);
}

@media (max-width: 430px) {
    :root {
        --unit: 4.68vw;
        --font-calendar: calc(var(--unit) * 0.625);
    }
}

.calendar-component {
display: flex;
flex-direction: column;
height: 100dvh;
background-color: #222;
color: #fff;
}

.calendar-component .header {
display: flex;
justify-content: center;
align-items: center;
padding: calc(var(--unit) * 0.5);
font-size: calc(var(--unit) * 1.5);
gap: calc(var(--unit) * 0.25);
}

.calendar-component .header .month {
font-weight: bold;
}

.weekDays {
display: grid;
grid-template-columns: repeat(7, 1fr);
gap: 1px;
width: 100%;
text-align: right;
}

.weekDays .day {
display: flex;
align-items: center;
justify-content: right;
padding: calc(var(--unit) * 0.125);
margin: calc(var(--unit) * 0.125);
min-width: var(--unit);
min-height: var(--unit);
font-size: var(--font-calendar);
}

.monthGrid {
display: grid;
grid-template-columns: repeat(7, 1fr); /* Ensure 7 columns for days */
gap: 1px;
height: 100%;
width: 100%;
}

.cell {
background-color: #111;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: var(--font-calendar);
}

.cell.outsideMonth {
background-color: #151515;
}

.cell.previousDay {
  opacity: 0.5;
}

.cell.validDay {
color: #fff;
}

.cell .cell-heading {
display: flex;
justify-content: space-between;
width: 100%;
}

.cell .calendar-cell-tallies,
.cell .calendar-cell-date-number {
display: flex;
align-items: center;
justify-content: right;
padding: calc(var(--unit) * 0.125);
margin: calc(var(--unit) * 0.125);
}

.cell .calendar-cell-date-number.current {
position: relative;
z-index: 2;
}

.cell .calendar-cell-date-number.current::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(var(--unit) * 1.25);
  height: calc(var(--unit) * 1.25);
  background-color: rgb(207, 31, 0);  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: -1; /* Ensure the circle is behind the content */
}

.cell .cell-content {
padding: 4px;
flex: 1;
}
